<template>
  <v-stepper>
    <v-stepper-header>
      <v-stepper-step
        step="~"
        :complete-icon="`mdi-arrow-right`"
        :color="`primary`"
        :rules="[() => true]"
        class="job-stepper-title"
        complete
      >
        <span
          :class="`text-uppercase ${domain.length > 15 ? `small` : ''}`"
          :title="domain"
        >{{ domain }}</span>
        <span class="job-percentage-complete">{{ percentage() }}%</span>
      </v-stepper-step>
      <v-btn
        icon
        class="stepper-items-switcher"
        @click="showItems = !showItems"
      >
        <v-icon
          v-show="!showItems"
          small
        >
          mdi-eye
        </v-icon>
        <v-icon
          v-show="!!showItems"
          small
        >
          mdi-eye-off
        </v-icon>
      </v-btn>
    </v-stepper-header>

    <v-divider />

    <v-stepper-items v-show="showItems">
      <div
        v-for="(step, i) of steps"
        :key="i"
      >
        <v-stepper-step
          :step="i + 1"
          :rules="typeof getItemByStep(i + 1) === 'object' ? [() => getItemByStep(i + 1).running === true ? true : getItemByStep(i + 1).success] : []"
          :complete="typeof getItemByStep(i + 1) === 'object' && getItemByStep(i + 1).success === true"
          :complete-icon="typeof getItemByStep(i + 1) === 'object' && getItemByStep(i + 1).skippedStep === true ? `mdi-debug-step-over` : undefined"
          :color="typeof getItemByStep(i + 1) === 'object' && getItemByStep(i + 1).skippedStep === true ? `amber darken-3` : undefined"
          :class="typeof getItemByStep(i + 1) === 'object' && getItemByStep(i + 1).running === true ? `blue-grey lighten-5 loading-background` : undefined"
        >
          <!-- {{ step }} -->
          <small class="desc">{{ Object.values(step)[0] }}</small>
          <small class="time">
            <div v-if="typeof getItemByStep(i + 1) === 'object' && getItemByStep(i + 1).running === true">
              <time-ago
                :key="`time_ago_${i + 1}`"
                :date="getItemByStep(i + 1).startDate"
                :retries="getItemByStep(i + 1).retriesNum"
              />
            </div>
            <div v-else>
              {{ typeof getItemByStep(i + 1) === 'object' ? $helper.timeFilter(getItemByStep(i + 1).startDate) : '00:00:00' }} /
              {{ typeof getItemByStep(i + 1) === 'object' ? $helper.timeFilter(getItemByStep(i + 1).finishDate) : '00:00:00' }}
            </div>
          </small>
          <div v-if="typeof getItemByStep(i + 1) === 'object' && getItemByStep(i + 1).running !== true">
            <tooltip
              v-if="getItemByStep(i + 1) !== false && typeof getItemByStep(i + 1) === 'object' && getItemByStep(i + 1).skippedStep !== true"
              :text="$t('Continue from this step')"
            >
              <v-btn
                icon
                x-small
                class="proceed-btn"
              >
                <v-icon
                  small
                  @click="proceedFrom(i + 1, getItemByStep(i + 1))"
                >
                  mdi-play
                </v-icon>
              </v-btn>
            </tooltip>
            <tooltip
              v-if="getItemByStep(i + 1) !== false && typeof getItemByStep(i + 1) === 'object' && getItemByStep(i + 1).skippedStep !== true"
              :text="$t('Restart this step')"
            >
              <template>
                <v-btn
                  icon
                  x-small
                  class="restart-btn"
                >
                  <v-icon
                    small
                    @click="restart(i + 1, getItemByStep(i + 1))"
                  >
                    mdi-replay
                  </v-icon>
                </v-btn>
              </template>
            </tooltip>
          </div>
          <div v-else>
            <tooltip
              v-if="getItemByStep(i + 1) !== false && typeof getItemByStep(i + 1) === 'object' && getItemByStep(i + 1).skippedStep !== true"
              :text="$t('Stop at this step')"
            >
              <template>
                <v-btn
                  icon
                  x-small
                  class="stop-btn"
                >
                  <v-icon
                    small
                    @click="stop(i + 1, getItemByStep(i + 1))"
                  >
                    mdi-stop
                  </v-icon>
                </v-btn>
              </template>
            </tooltip>
          </div>
        </v-stepper-step>
        <v-divider />
      </div>
    </v-stepper-items>
    <!--/v-stepper-header-->
  </v-stepper>
</template>

<script>
export default {
  name: 'GeneratorJobStepper',
  props: {
    taskId: {
      type: String,
      default: ''
    },
    domain: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    steps: {},
    showItems: true,
    timers: {}
  }),
  mounted () {
    this.steps = [
      { Domain: 'Create a domain' },
      { 'Domain Provider': 'Checking provider domains list' },
      { Cloudflare: 'Add domain to cloudflare' },
      { NSS: 'Change Nameservers for domain' },
      { DNS: 'Change main A-record for domain' },
      { 'Check DNS': 'DNS Change Confirmation' },
      { VestaCP: 'Adding a domain to the VestaCP server' },
      { 'Check Domain': 'Domain registration verification' },
      { 'Check NSS': 'NSS Change Confirmation' },
      { HTTPS: 'HTTPS setup (SSL)' }
    ]
  },
  methods: {
    percentage() {
      const MAX_STEPS = 10
      const item = this.getItemSteps()
      if (item === false) {
        return 0
      }
      let successSteps = 0
      for (const step in item) {
        const itm = item[step]
        if (itm.success === true) {
          successSteps += 1
        }
      }
      return (successSteps / MAX_STEPS) * 100
    },
    getItemSteps() {
      return typeof this.data[this.domain] === 'object' ? this.data[this.domain] : false
    },
    getItemByStep(step) {
      return typeof this.getItemSteps()[step] === 'object' ? this.getItemSteps()[step] : false
    },
    async proceedFrom(step, data) {
      this.$set(data, 'success', false)
      this.$set(data, 'running', true)
      this.$set(data, 'startDate', new Date())
      this.$set(data, 'finishDate', null)
      await this.$store.dispatch('tasksGenerator/restartJobInTask', {
        id: this.taskId,
        inputData: {
          job: step,
          domain: this.domain,
          action: 'proceed'
        }
      })
    },
    async restart(step, data) {
      this.$set(data, 'success', false)
      this.$set(data, 'running', true)
      this.$set(data, 'startDate', new Date())
      this.$set(data, 'finishDate', null)
      await this.$store.dispatch('tasksGenerator/restartJobInTask', {
        id: this.taskId,
        inputData: {
          job: step,
          domain: this.domain,
          action: 'restart'
        }
      })
    },
    async stop(step, data) {
      this.$set(data, 'success', false)
      this.$set(data, 'running', false)
      this.$set(data, 'startDate', data.startDate)
      this.$set(data, 'finishDate', new Date())
      await this.$store.dispatch('tasksGenerator/restartJobInTask', {
        id: this.taskId,
        inputData: {
          job: step,
          domain: this.domain,
          action: 'stop'
        }
      })
    }
  }
}
</script>

<style lang="scss">
.job-stepper-container {
  margin-top: 10px;
  margin-bottom: 10px;
}
.job-stepper-container small.desc {
  padding-top: 7px;
  color: #606060 !important;
  line-height: 12px !important;
}
.job-stepper-container small.time {
  padding-top: 15px;
  color: #9f9f9f !important;
}
.job-stepper-container .v-stepper__header {
  background-color: #f4f4f4;
  font-size: 16px;
  box-shadow: none;
}
.job-stepper-container .v-stepper__label {
  color: #949da7 !important;
}
.job-stepper-container .v-stepper__step {
  padding: 15px;
}
.job-percentage-complete {
  margin-top: 7px;
  color: #c4c4c4;
  font-size: 12px;
}
.stepper-items-switcher {
  position: absolute;
  right: 7px;
  top: 7px;
}
.proceed-btn {
  position: absolute;
  right: 7px;
  bottom: 12px;
}
.restart-btn {
  position: absolute;
  right: 30px;
  bottom: 12px;
}
.stop-btn {
  position: absolute;
  right: 7px;
  bottom: 12px;
}
.stop-btn.v-btn:hover:before {
  color: #ef9191;
  opacity: 0.2;
}
</style>
