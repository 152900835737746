<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          :title="title"
          :table-headers="tableHeaders"
          :form-scheme="formScheme"
          :resource="resource"
          :expanded="expanded"
          :show-expand="showExpand"
          :single-expand="singleExpand"
          :item-class-fn="itemClassFn"
          :submit-text="getEditedItem.id ? 'Restart task' : 'Start task'"
          @inputChange="handleChange"
          @dialogChange="handleDialog"
          @currentItems="handleCurrentItems"
        >
          <template #[`item.completed_at`]="{ item }">
            <table-date :date="item.completed_at" />
            <slot
              name="dates"
              :item="item"
            />
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td
              v-if="Object.keys(item.log || {}).length > 0"
              :colspan="headers.length"
            >
              <v-row
                v-if="Object.keys(item.log || {}).length > 0"
                class="job-stepper-container"
              >
                <v-col
                  v-for="(domain, key) of Object.keys(item.log || {})"
                  :key="key"
                  xl="2"
                  lg="3"
                  md="3"
                  sm="4"
                  class="align-center"
                >
                  <generator-job-stepper
                    :data="item.log"
                    :domain="domain"
                    :task-id="item.id"
                  />
                </v-col>
              </v-row>
            </td>
            <td
              v-else
              :colspan="headers.length"
              class="v-data-table__empty-wrapper"
            >
              Loading... Please wait
            </td>
          </template>
        </table-data>
        <domains-generator
          v-if="domainsGeneratorDialog"
          :advanced="true"
          :dialog="domainsGeneratorDialog"
          :edited-item="domainsGeneratorEditedItem"
          @close="domainsGeneratorDialogClose"
          @result="domainsGeneratorResult"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import DomainsGenerator from '../domains/dialogs/DomainsGenerator'
import GeneratorJobStepper from './components/GeneratorJobStepper'
import Vue from 'vue'
import numWords from '../../../utils/numWords'

export default {
  name: 'Generator',
  filters: {
    dateFilter (date = null) {
      try {
        if (!date || date === undefined) {
          return ''
        }
        if (date.indexOf('T')) {
          const dateArray = date.split('T')
          return dateArray.length === 2 ? `${dateArray[0]} ${dateArray[1].substring(0, 8)}` : ''
        }
      } catch (e) {
        return ''
      }
    }
  },
  components: {
    DomainsGenerator,
    GeneratorJobStepper
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'tasks.generator',
    expanded: [],
    showExpand: true,
    singleExpand: true,
    editedItem: {
      registration: true,
      changeNss: true,
      // ignoringErrors: true,
      https: true
      // domains: []
    },
    //
    domainsGeneratorDialog: false,
    domainsGeneratorDefaultItem: {
      words: 2,
      separator: '',
      quantity: 1,
      keyword: '',
      tlds: ['com'],
      suffix: '',
      suffix_type: '',
      suffix_len: 0,
      prefix: '',
      prefix_type: '',
      prefix_len: 0
    },
    domainsGeneratorEditedItem: {}
  }),
  metaInfo() {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('tasks.generator.meta.title')} 00:00:00 %s`
    }
  },
  computed: {
    getEditedItem() {
      return this.$store.getters[`__${this.resource}/getEditedItem`] || []
    },
    getLockedEntities() {
      return this.$store.getters['lockedEntities/getLockedEntities']
    }
  },
  watch: {
    async 'editedItem.domain_provider_id' (val) {
      if (val) {
        const result = await this.$store.dispatch('domainsProviders/getDefaultNss', { id: val })
        if (result) {
          if (!this.editedItem.cloudflare_id) {
            Vue.set(this.editedItem, 'ns_records', result)
          }
        }
      } else {
        this.formScheme.ns_records.disabled = false
        Vue.set(this.editedItem, 'ns_records', [])
      }
    },
    async 'editedItem.cloudflare_id' (val) {
      if (val) {
        const result = await this.$store.dispatch('cloudflare/getAccountNSS', { id: val })
        if (result) {
          Vue.set(this.editedItem, 'ns_records', result)
          this.formScheme.ns_records.disabled = true
        }
      } else {
        this.formScheme.ns_records.disabled = false
        Vue.set(this.editedItem, 'ns_records', [])
      }
    },
    async 'editedItem.hosting_server_id' (val) {
      if (val) {
        const selectedServer = await this.$store.dispatch('hostingServers/getHostingServerData', { id: val })
        if (selectedServer) {
          if (selectedServer.with_cp === true) {
            /* IF SELECTED SERVER WITH CONTROL PANEL (Vesta, Hestia, E.t.c) */

            this.formScheme.hosting_server_user.disabled = false

            this.formScheme.address_record.items = await this.$store.dispatch('hostingServers/getHostingServerIp', { id: val })
            this.formScheme.address_record.type = 'select'
            this.formScheme.address_record.clearable = false
            Vue.set(this.editedItem, 'address_record', this.formScheme.address_record.items[0].value)

            this.formScheme.hosting_server_user.items = await this.$store.dispatch('hostingServers/getHostingServerUsers', { id: val })
            this.formScheme.hosting_server_user.type = 'select'
            this.formScheme.hosting_server_user.disabled = false
            Vue.set(this.editedItem, 'hosting_server_user', this.formScheme.hosting_server_user.items[0])
          } else {
            /* IF SERVER WITHOUT CP (Keitaro, Binom, E.t.c.) */
            this.formScheme.hosting_server_user.disabled = true

            this.formScheme.address_record.items = selectedServer.ip
            this.formScheme.address_record.type = 'text'
            Vue.set(this.editedItem, 'address_record', selectedServer.ip[0])
          }
        }
      } else {
        this.formScheme.hosting_server_user.disabled = true

        this.formScheme.address_record.type = 'text'
        Vue.set(this.editedItem, 'address_record', '')
        Vue.set(this.editedItem, 'hosting_server_user', '')
      }
    },
    async 'editedItem.domains' (val) {},
    async 'editedItem.affiliate_id' (val) {
      if (!this.editedItem.name) {
        const affiliates = await this.$store.dispatch('affiliates/all', false)
        const affiliate = affiliates.find(item => item.affiliate_id === val)
        if (affiliate) {
          if (!this.editedItem.name && ((this.editedItem || {}).domains || []).length) {
            const taskName = `${this.$t('tasks.generator.tableForm.nameTemplate', {
                domainsNum: numWords(((this.editedItem || {}).domains || []).length),
                affiliateName: affiliate.name,
                affiliateTeam: affiliate.team_id
              })}`
            this.editedItem.name = taskName
          }
        }
      }
    }
  },
  async mounted () {
    this.title = this.$t('tasks.generator.title')

    this.tableHeaders = [
      {
        text: this.$t('tasks.generator.table.name'),
        align: 'start',
        value: 'name'
      },
      {
        text: this.$t('tasks.generator.table.statusText'),
        align: 'start',
        value: 'status_text',
        sortable: false
      },
      {
        text: this.$t('tasks.generator.table.domainsNum'),
        align: 'center',
        value: 'domains_num',
        width: '100px'
      },
      {
        text: this.$t('tasks.generator.table.completedAt'),
        align: 'center',
        value: 'completed_at',
        width: '100px'
      }
    ]

    this.formScheme = {
      domains: {
        type: 'combobox',
        label: this.$t('tasks.generator.tableForm.domains'),
        createRules: 'req',
        updateRules: [],
        chips: true,
        multiple: true,
        md: 12,
        items: this.editedItem.domains,
        hint: this.$t('domains.providers.domains.dialog.hint'),
        appendIcon: 'brain',
        appendCopyBtn: true,
        appendClickFn: async (e, t) => this.domainGeneratorDialogOpen(e, t),
        clearable: true
      },
      affiliate_id: {
        type: 'autocomplete',
        label: this.$t('tasks.generator.tableForm.affiliate'),
        items: await this.$store.dispatch('affiliates/all', true),
        createRules: [], // v => !!v || this.$t('tasks.generator.tableForm.validation.fieldIsRequired')
        updateRules: [],
        md: 6,
        changeFn: async (i, val) => {
          const affiliates = await this.$store.dispatch('affiliates/all', false)
          const affiliate = affiliates.find(item => item._id === val)
          if (affiliate) {
            // const affiliateArr = affiliate.text.split(' | ')
            // const affiliateArr = []
            // if (affiliateArr.length === 2) {
            if (((this.editedItem || {}).domains || []).length) {
              const taskName = `${this.$t('tasks.generator.tableForm.nameTemplate', {
                  domainsNum: numWords(this.editedItem.domains.length),
                  affiliateName: this.$helper.capFirstLetter(affiliate.name),
                  affiliateTeam: affiliate.team
                })}`
              Vue.set(this.editedItem, 'name', taskName)
            }
            // }
          }
        }
      },
      domain_provider_id: {
        type: 'autocomplete',
        label: this.$t('tasks.generator.tableForm.domainProvider'),
        items: await this.$store.dispatch('domainsProviders/getDomainsProvidersList'),
        createRules: 'req', // v => !!v || this.$t('tasks.generator.tableForm.validation.fieldIsRequired')
        updateRules: 'req',
        md: 6
      },
      hosting_server_id: {
        type: 'autocomplete',
        label: this.$t('tasks.generator.tableForm.hostingServer'),
        items: await this.$store.dispatch('hostingServers/getHostingServersList'),
        createRules: [], // v => !!v || this.$t('tasks.generator.tableForm.validation.fieldIsRequired')
        updateRules: [],
        md: 6,
        clearable: true
      },
      hosting_server_user: {
        type: 'select',
        label: this.$t('tasks.generator.tableForm.hostingServerUser'),
        // const futureServerUsers = await this.$store.dispatch('hostingServers/getHostingServerUsers', { id: val.futureServerId })
        items: ['user'],
        readonly: true,
        clearable: false,
        disabled: true,
        createRules: [], // v => !!v || this.$t('tasks.generator.tableForm.validation.fieldIsRequired')
        updateRules: [],
        md: 6
      },
      address_record: {
        type: 'text',
        items: [],
        label: this.$t('domains.tableForm.ip'),
        createRules: [
          (v) =>
            /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi.test(
              v
            ) || 'IP Address is invalid'
        ], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
        updateRules: [
          (v) =>
            /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi.test(
              v
            ) || 'IP Address is invalid'
        ],
        // forEditedOnly: true,
        // readonly: true,
        clearable: true,
        md: 12
      },
      cloudflare_id: {
        type: 'autocomplete',
        ref: 'cfid',
        label: this.$t('tasks.generator.tableForm.cloudflare'),
        items: await this.$store.dispatch('cloudflare/getAllCloudflareAccounts'),
        createRules: [], // v => !!v || this.$t('tasks.generator.tableForm.validation.fieldIsRequired')
        updateRules: [],
        md: 6
      },
      ns_records: {
        type: 'combobox',
        ref: 'nss',
        smallChips: true,
        chips: true,
        multiple: true,
        items: this.editedItem.ns_records,
        label: this.$t('domains.tableForm.nss'),
        createRules: 'req', // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
        updateRules: 'req',
        md: 6,
        fn: (entity, value) => {
          if (value instanceof Array) {
            if (/[\s,]/.test(value[0])) {
              const preparedNss = value[0].split(/[\s,]/).map((m) => m.trim()).filter(f => f.length > 6)
              const getEditedItem = Object.assign({}, this.getEditedItem, { ns_records: preparedNss })
              this.$store.commit(`__${this.resource}/setEditedItem`, getEditedItem)
            }
          }
        }
      },
      name: {
        type: 'text',
        label: this.$t('tasks.generator.tableForm.name'),
        placeholder: this.$t('tasks.generator.tableForm.namePlaceholder'),
        createRules: [],
        updateRules: [],
        md: 12
      },
      registration: {
        type: 'switch',
        label: 'Submit a domains registration request',
        inset: true,
        md: 4
      },
      changeNss: {
        type: 'switch',
        label: 'Set NSS for domains on provider via API',
        inset: true,
        md: 4
      },
      // ignoringErrors: {
      //   type: 'switch',
      //   inset: true,
      //   label: 'Ignore all possible errors',
      //   md: 4
      // }
      https: {
        type: 'switch',
        inset: true,
        label: 'Enable HTTPS',
        md: 4
      }
    }

    if (this.$currentUser.role === process.env.VUE_APP_MAIN_ROLE || this.$currentUser.global === true) {
      this.tableHeaders = this.$helper.addToArray(this.tableHeaders, {
        text: this.$t('tasks.generator.table.team'),
        value: 'team'
      }, 1)
      // this.formScheme = this.$helper.addToObject(this.formScheme, 'affiliate_team_id', {
      //   type: 'autocomplete',
      //   label: this.$t('tasks.generator.tableForm.teams'),
      //   items: await this.$store.dispatch('affiliatesTeams/all'),
      //   createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
      //   updateRules: [],
      //   md: 12
      // }, 'notes')
    }

    this.$eventBus.$on('generatorTaskRefresh', (data) => {
      // const index = this.currentItems.findIndex((i) => i.id === data.id)
      // this.$set(this.currentItems, index, data)

      this.$store.commit(`__${this.resource}/saveItem`, data)
      // console.log(this.$helper.getNestedObj([, this.domain], data))

      // if (this.taskId in data) {
      //   if (this.domain in data[this.taskId]) {
      //     const [step, value] = Object.entries(data[this.taskId][this.domain])
      //     this.data[this.domain][step] = value
      //   }
      // }
    })
  },
  methods: {
    handleChange (changes) {
      if (changes.domains instanceof Array && typeof changes.domains[0] === 'string') {
        if (/[\s,]/.test(changes.domains[0])) {
          changes.domains = changes.domains[0].split(/[\s,]/)
          changes.domains = changes.domains.map((d) => d.trim()).filter(Boolean)
          // TODO: DELETED THIS
          // changes.name = `Creation ${changes.domains.length} domain(s) for StarForce team`
        }
      }
      this.editedItem = changes
    },
    // Set default values for form
    handleDialog (event) {
      if (event.dialog === true) {
        let editedItem = event.item
        editedItem = { ...this.editedItem, ...editedItem, ...event.item.conditions }
        this.$store.commit(`__${this.resource}/setEditedItem`, editedItem)
      }
    },
    // handleSelected (selected) {
    //   this.selected = selected
    // },
    handleCurrentItems(items) {
      if (items.length > 0) {
        this.currentItems = items
      }
    },
    domainsGeneratorResult(result) {
      const getEditedItem = Object.assign({}, this.getEditedItem, { domains: result.length > 0 ? result[0] : [] })
      this.$store.commit(`__${this.resource}/setEditedItem`, getEditedItem)
    },
    domainGeneratorDialogOpen() {
      this.domainsGeneratorEditedItem = Object.assign({}, this.domainsGeneratorDefaultItem)
      this.domainsGeneratorDialog = true
    },
    domainsGeneratorDialogClose() {
      this.domainsGeneratorDialog = false
      this.domainsGeneratorEditedItem = this.domainsGeneratorDefaultItem
    },
    required(value) {
      if (value instanceof Array && value.length === 0) {
        return this.$t('tasks.generator.tableForm.validation.fieldIsRequired')
      }
      return !!value || this.$t('tasks.generator.tableForm.validation.fieldIsRequired')
    },
    itemClassFn(item) {
      let classes
      if (this.getLockedEntities.indexOf(item._id) > -1) {
        classes += ' blue-grey lighten-5 loading-background '
      }
      if (item.with_error === true) {
        classes += ' red lighten-5 '
      }
      return classes
    }
    // prepareLog(item) {
    //   const result = {}
    //   item.map((l) => {
    //     if (result[l.domain] === undefined) {
    //       result[l.domain] = {}
    //     }
    //     if (result[l.domain][l.step] === undefined) {
    //       result[l.domain][l.step] = {}
    //     }
    //     result[l.domain][l.step] = l
    //     return l
    //   })
    //   return result
    // }
  }
}
</script>
